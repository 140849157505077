import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { IronGrey, Pink, Purple } from "../colors"

const Container = styled.div`
  position: relative;
  margin: -48px auto 64px;
  width: 64px;
`

const Root = styled.div`
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${Purple};
  cursor: pointer;
  transition: 0.25s ease;
  z-index: 12;

  &:hover {
    transform: scale(1.1);
  }
`

const Text = styled.div`
  position: absolute;
  padding-left: 24px;
  padding-top: 16px;
  font-size: 20px;
`

interface PageDownButtonProps {
  id: string
}

const PageDownButton = ({ id }: PageDownButtonProps) => {
  return (
    <Container>
      <AnchorLink href={`#${id}`} offset="-34">
        <Root id={id}>
          <Text>
            <FontAwesomeIcon icon={faCaretDown} size="lg" color={`${Pink}`} />
          </Text>
        </Root>
      </AnchorLink>
    </Container>
  )
}

PageDownButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default PageDownButton
