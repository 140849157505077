import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { Swipeable, EventData } from 'react-swipeable'

import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'
import Content from '../components/Merry/Project/Content'
import Layout from '../components/Merry/Project/Layout'
import Header from '../components/Merry/Project/Header'
import Sidebar from '../components/Merry/Sidebar'
import Bio from '../components/bio'
import PageUpButton from '../components/Merry/Project/PageUpButton'
import PageDownButton from '../components/Merry/Project/PageDownButton'
import Footer from '../components/Footer'
import PageWrap from '../components/PageWrap'

interface Props {
  data: {
    markdownRemark: any
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
  pageContext: any
  location: any
}

const ProjectPostTemplate = ({ data, pageContext, location }: Props) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  const navStartsOpen = location.state && location.state.navOpen !== undefined? location.state.fromFeed: true

  return (
    <PageWrap
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
      navOpen={navStartsOpen}
    >
      <div style={{width: '100%'}}>
      {
          post.frontmatter.featuredImage && <Header
            image={post.frontmatter.featuredImage.childImageSharp.fluid}
            title={post.frontmatter.title}
          />
        }
        <Content>
          { post.frontmatter.featuredImage? 
            <PageDownButton id="pageDownButton" onClick={()=> {}}/>
          : 
            <h1>{ post.frontmatter.title }</h1>
          }
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />

          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li style={{maxWidth: '40%'}}>
              {previous && (
                <Link to={`/project${previous.fields.slug}`} rel="prev" state={{ navOpen: false}}>
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li style={{maxWidth: '40%'}}>
              {next && (
                <Link to={`/project${next.fields.slug}`} rel="next" state={{ navOpen: false}}>
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </Content>
      </div>
    </PageWrap>
  )
}

export default ProjectPostTemplate

export const pageQuery = graphql`
  query ProjectPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author {
          name
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`