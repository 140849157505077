import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"
import { SizeSM, rhythm } from '../vars';

const Root = styled.div`

`;

const ImageContainer = styled.div`
    width: 100%;
    max-height: 800px;
    min-height: 300px;
    overflow: hidden;
    position: relative;
`

const Content = styled.div`
    position: absolute;
    z-index: 10;
    bottom: 20px;
    left: ${rhythm(1.5)};
    right: ${rhythm(1.5)};
    text-align: center;
`

const Title = styled.h1`
    color: #fff;
    font-size: 2rem;

@media screen and (min-width: ${SizeSM}) {
    font-size: 2.5rem;
}
`


interface HeaderProps {
    image?: any
    title: string
}

const Header = ({ image, title }: HeaderProps) => {
    return (
        <Root>
            <ImageContainer>
                <Img fluid={image} style={{overflow: 'initial', position: 'static'}}/>
                <Content>
                    <Title>{ title }</Title>
                </Content>
            </ImageContainer>
        </Root>
    )
}

export default Header
